import React, { useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import useWindowSize from './hooks/useWindowSize';
import WebsocketContextProvider from './context/WebsocketContext';
import { Banner, EnumBannerPlacement } from './elements/banner';
import Registration from './pages/Registration/Registration'
import Download from './pages/Download/Download';
import logo from './assets/icons/SoundBoothIcon.svg';
import './App.css';

function App() {
  const {width} = useWindowSize();

  const bannerPlacement = useMemo(() => {
    if(width > 800)
      return EnumBannerPlacement.Left;
    return EnumBannerPlacement.Bottom;
  }, [width]);
  
  return (
    <div className="App">
      <Banner placement={bannerPlacement}></Banner>
      {bannerPlacement === EnumBannerPlacement.Left && <Banner placement={EnumBannerPlacement.Right}></Banner>}
      
      <header>
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <WebsocketContextProvider>
        <Router>
          <Switch>
            <Route path="/download" exact component={Download} />
            <Route path="/:token?" exact component={Registration} />
          </Switch>
        </Router>
      </WebsocketContextProvider>
    </div>
  );
}

export default App;
