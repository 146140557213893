import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import styles from './index.module.css';
import documentIcon from '../../assets/icons/document.svg';

import fileSaver from 'file-saver';
import { RequestTypes, useWebsocketContext } from '../../context/WebsocketContext';
import useQueryString from '../../hooks/useQueryString';

function fallbackCopyToClipboard(text: string) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }
  document.body.removeChild(textArea);
}

function copyToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export default function Download(): JSX.Element {

  // get query string 'id'
  // send a download message to the websocket server
  // show the download buttons with the link

  const [isLoading, setIsLoading] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [requestProgress, setRequestProgress] = useState(true);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [audioUrl, setAudioUrl] = useState<string>('');

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    window.addEventListener('click', () => videoRef.current?.play());
  });

  const {id: secret} = useQueryString();
  const {onConnect, onMessage, send} = useWebsocketContext();

  const isIOS = useMemo(() => [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document), []);

  const isSafari = useMemo(() => /^((?!chrome|CriOS|FxiOS|EdgiOS|android).)*safari/i.test(navigator.userAgent), []);

  useEffect(() => {
    onConnect(() => {
      setRequestProgress(true);
    });
    onMessage((data) => {
      if(data.type === RequestTypes.DownloadUrl)
      {
        setIsLoading(false);
        if(data.audioUrl && data.videoUrl){
          setVideoUrl(data.videoUrl);
          setAudioUrl(data.audioUrl);
        }else {
          setUploadProgress(data.progress);
          setTimeout(() => {
            setRequestProgress(true);
          }, 500);
        }
      }
    });
  }, [onConnect, onMessage]);

  useEffect(() => {
    if(requestProgress){
      send({type: RequestTypes.DownloadUrl, secret: decodeURIComponent(secret)});
      setRequestProgress(false);
    }
  }, [requestProgress, secret, send]);

  const saveVideo = useCallback(
    () => {
      fileSaver.saveAs(videoUrl, "soundbooth.mp4");
    },
    [videoUrl],
  );
  const saveAudio = useCallback(
    () => {
      fileSaver.saveAs(audioUrl, "soundbooth.mp3");
    },
    [audioUrl],
  );
  
  const copyUrl = useCallback(
    () => {
      copyToClipboard(window.location.toString());
    },
    [],
  )

  const isInProgress = useMemo(() => !audioUrl || !videoUrl, [audioUrl, videoUrl]);

  const safariWarning = useMemo(() => isIOS && !isSafari && <>
    <div style={{position: 'fixed', width: '100vw', height: '100vh', backgroundColor: '#000000cf', zIndex: 2, fontFamily: 'RobotoMedium', fontSize: '2em'}}>
      <div style={{height: '50%', marginTop: '30vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white'}}>
          <img src={documentIcon} alt="document" style={{height: '10rem'}} />
          <span style={{width: '70%', marginTop: '5rem', lineHeight: '3.1rem', textAlign: 'center'}}>Please open this page in safari in order to be able to download your track.</span>
        </div>
        <div>
        <button onClick={copyUrl} className="active">Copy Url</button>
        </div>
      </div>
    </div>
  </>, [isIOS, isSafari, copyUrl]);

  const progressBar = useMemo(() => 
    <div style={{width: '100%', height: '0.4rem', backgroundColor: 'gray', borderRadius: '8px', overflow: 'hidden'}}>
      <div style={{width: uploadProgress + '%', height: '100%', backgroundColor: '#f4200f', background: 'linear-gradient(90deg, rgba(244,32,15,1) 0%, rgba(255,103,0,1) 100%)'}}></div>
    </div>, [uploadProgress]);

  const progressView = useMemo(() => !isLoading && isInProgress && 
    <>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '0 8rem', textAlign: 'center'}}>
        <div style={{fontFamily: 'Druk XCond Web', color: 'white', fontSize: '7.4rem', marginBottom: '8rem'}}>YOUR TRACK IS STILL UPLOADING</div>
        <div style={{fontFamily: 'Druk XCond Web', color: 'white', fontSize: '6.4rem', marginBottom: '2rem'}}>
          {Math.ceil(uploadProgress)}%
        </div>
        <div style={{width: '100%', marginBottom: '2rem'}}>
          {progressBar}
        </div>
        <div style={{fontFamily: 'UniversBlackExtObl', color: '#B7B7B7', fontSize: '1.8rem', lineHeight: '3.1rem'}}>Uploading Video please check out later.</div>
      </div>
    </>, [uploadProgress, isInProgress, isLoading, progressBar]);

  const downloadView = useMemo(() => !isLoading && !isInProgress &&
    <>
      <div className={styles.plasticBg}></div>
      <div className={styles.albumCover}>
        <video ref={videoRef} src={videoUrl} autoPlay loop muted playsInline></video>
      </div>
      <div className={styles.innerContainer}>
        <div className={styles.title}>
          <span className={styles.success}>YOUR TRACK IS</span>
          <span className={styles.success}>READY TO BE SHARED</span>
        </div>
        <div className={styles.buttonContainer}>
          {videoUrl && <button onClick={saveVideo} className="active">Download Session</button>}
          {audioUrl && <button onClick={saveAudio} className="active">Download Audio</button>}
        </div>
      </div>
    </> , [isInProgress, isLoading, audioUrl, videoUrl, saveVideo, saveAudio]);

  return (<>
        {safariWarning}
        <div className={styles.container}>
          <div className={styles.subContainer}>
            {isLoading && <div style={{fontFamily: 'Druk XCond Web', color: 'white', fontSize: '7.4rem'}}>Loading..</div>}
            {downloadView}
            {progressView}
          </div>
        </div>
      </>
  );
}
