import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { RequestTypes, ResponseType, useWebsocketContext } from '../../context/WebsocketContext';
import greenTick from '../../assets/icons/green-tick.svg';
import styles from './index.module.css';

type Props  = {
  match: {
    params: {
      token: string
    }
  }
};

export default function Registration({match: {params: {token}}}: Props): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [code, setCode] = useState<string | null>(null);
  const [isTCAccepted, setIsTCAccepted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [boothToken, setBoothToken] = useState<string | null>(null);
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

  const { send, onMessage } = useWebsocketContext();

  const processIncomingMessage = useCallback((data: ResponseType) => {
    console.log('got new message:', data);
    switch (data.type) {
      case RequestTypes.BoothValidation:
        setIsTokenValid(data.isValid);
        break;
      case RequestTypes.Registration:
        if (data.reason) {
          console.error('Registration failed with the reason', data.reason);
          return;
        }
        setCode(data.code);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    onMessage(processIncomingMessage);
    // as soon as we start, send the booth token to the server to verify if it's still valid
    setBoothToken(token);
    if (!token) {
      setIsTokenValid(false);
      return;
    }
    send({ type: RequestTypes.BoothValidation, token })
  }, [token, send, onMessage, processIncomingMessage]);

  useEffect(() => {
    setName(localStorage.getItem('name') ?? '');
    setEmail(localStorage.getItem('email') ?? '');
  }, []);

  const isNameValid = useMemo(() => name, [name]);

  const isEmailValid = useMemo(() => {
    const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if ((!email || !regex.test(email)))
      return false;
    return true;
  }, [email]);

  const isTCValid = useMemo(() => isTCAccepted, [isTCAccepted]);

  const isFormValid = useMemo(() => isNameValid && isEmailValid && isTCValid, [isNameValid, isEmailValid, isTCValid]);

  const errorMessages = useMemo(() => {
    return !isSubmitted ? [] :
      [
        !isNameValid ? 'Please enter your name' : '',
        !isEmailValid ? 'Please enter your email' : '',
        !isTCValid ? 'Please accept Terms & Conditions' : ''
      ];
  }, [isSubmitted, isNameValid, isEmailValid, isTCValid]);

  const onSubmit = useCallback(
    () => {
      setIsSubmitted(true);
      if (!isTCAccepted || !boothToken)
        return;
      localStorage.setItem('name', name);
      localStorage.setItem('email', email);
      send({ type: RequestTypes.Registration, token: boothToken, email, name });
    },
    [email, name, isTCAccepted, boothToken, send],
  );

  const tokenInvalidView = useMemo(() => <>
    <div className={styles.errorView}>
      <div className={styles.title}>
        REGISTRATION ERROR
      </div>
      <div style={{ marginTop: '2rem', padding: '0 3.6rem', textAlign: 'center' }}>The code has expired. Please scan the QR code in the soundbooth again.</div>
    </div>
  </>, []);

  const registrationView = useMemo(() => <>
    <div className={styles.title}>
      REGISTRATION
    </div>
    <input type="text" maxLength={30} placeholder="Name" value={name} className={!isSubmitted || isNameValid ? '' : 'invalid'} onChange={(e) => setName(e.target.value)} />
    <input type="email" maxLength={320} placeholder="E-mail address" value={email} className={!isSubmitted || isEmailValid ? '' : 'invalid'} onChange={(e) => setEmail(e.target.value)} />
    <div className={`checkbox ${!isSubmitted || isTCValid ? '' : 'invalid'}`} onClick={() => setIsTCAccepted(i => !i)}>
      <div className='green-tick-container'>
        {isTCAccepted && <img src={greenTick} alt="green tick" className='green-tick' />}
      </div>
      I agree to <a href="https://www.snipes.com/content/agb.html" target="_blank" rel="noreferrer" style={{ color: '#FF6700', marginLeft: '5px' }}>Terms & Conditions</a>
    </div>
    {isTokenValid && <button className={`${styles.submitButton} ${isFormValid ? 'active' : ''}`} onClick={onSubmit}>SUBMIT</button>}
    {errorMessages.map((errorMessage, i) => {
      return errorMessage ? <div className='error' key={i}>{errorMessage}</div> : '';
    })}
  </>, [email, errorMessages, isEmailValid, isFormValid, isNameValid, isSubmitted, isTCAccepted, isTCValid, name, isTokenValid, onSubmit]);

  const codeView = useMemo(() => code ? <>
    <div className={styles.title}>
      REGISTRATION SUCCESSFUL
    </div>
    <div className={styles.individual}>This is your individual code to unlock the soundbooth</div>
    <div className={styles.codeViewer}>
      <div className={styles.codeDigit}><span>{code[0]}</span></div>
      <div className={styles.codeDigit}><span>{code[1]}</span></div>
      <div className={styles.codeDigit}><span>{code[2]}</span></div>
      <div className={styles.codeDigit}><span>{code[3]}</span></div>
    </div>
    <div className={styles.enterCode}>Enter this code on screen</div>
    <div className={styles.attention}>Attention! This code is only valid for 24 hours and will automatically expire afterwards.</div>
  </> : <></>, [code]);

  return (<>
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {isTokenValid === false && tokenInvalidView}
        {(isTokenValid === null || isTokenValid) && !code && registrationView}
        {isTokenValid && codeView}
      </div>
    </div>
  </>
  );
}
