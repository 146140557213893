import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import socketIOClient, { Socket } from "socket.io-client";
import { DefaultEventsMap } from 'socket.io-client/build/typed-events';

export type WebsocketData = {
  onConnect: (callback: () => void) => void;
  onMessage: (callback: Callback) => void;
  send: (data: RequestType) => void;
};

export const WebsocketContext = createContext<WebsocketData>({
  onConnect: (callback: () => void) => undefined,
  onMessage: (callback: Callback) => undefined,
  send: () => undefined,
});

export enum RequestTypes {
  BoothValidation = 0,
  Registration = 1,
  DownloadUrl = 2,
  BoothRegistration = 3
}
export enum RejectionReason {
  EmailExist,
  EmailNotValid,
  TokenExpired
}
export type BoothValidationRequest = {
  type: RequestTypes.BoothValidation;
  token: string;
}
export type RegistrationRequest = {
  type: RequestTypes.Registration;
  token: string;
  email: string; 
  name: string; 
}
export type DownloadUrlRequest = {
  type: RequestTypes.DownloadUrl;
  secret: string;
}
export type RequestType = BoothValidationRequest | RegistrationRequest | DownloadUrlRequest;
export type BoothValidationResponse = {
  type: RequestTypes.BoothValidation;
  isValid: boolean;
}
export type RegistrationResponse = {
  type: RequestTypes.Registration;
  reason: RejectionReason;
  code: string;
}
export type DownloadUrlResponse = {
  type: RequestTypes.DownloadUrl;
  progress: number;
  videoUrl?: string;
  audioUrl?: string;
}
export type ResponseType = BoothValidationResponse | RegistrationResponse | DownloadUrlResponse;
export type Callback = (data: ResponseType) => void;

export default function WebsocketContextProvider({ children }: JSX.ElementChildrenAttribute): JSX.Element
{
  // const socketUrl = 'ws://localhost:8080/';
  const socketUrl = 'wss://backend.sb.snipesusa.com/';
  let socket: Socket<DefaultEventsMap, DefaultEventsMap> = useMemo(() => socketIOClient(socketUrl), []);

  const [onConnectListeners] = useState<(() => void)[]>([]);
  const [onMessageListeners] = useState<Callback[]>([]);

  const onConnect = useCallback(
    (callback: () => void) => {
      onConnectListeners.push(callback);
    },
    [onConnectListeners],
  );
  const onMessage = useCallback(
    (callback: Callback) => {
        onMessageListeners.push(callback);
    },
    [onMessageListeners],
  );

  useEffect(() => {
    socket.on("connect", () => {
      onConnectListeners.forEach(c => c());
    });
    socket.on("message", data => {
      onMessageListeners.forEach(c => c(data))
    });
    return () => {
      socket.disconnect();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const send = useCallback(
    (data: RequestType) => {
      socket.send(data);
    },
    [socket]);

  return (
    <WebsocketContext.Provider
      value={{
        onConnect,
        onMessage,
        send,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
}

export const useWebsocketContext: () => WebsocketData = () => useContext(WebsocketContext);
