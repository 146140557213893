import React, { useMemo } from 'react';

// Hook
export default function useQueryString(): {[key: string]: string} {
  const params = useMemo(() => {
    const search = window.location.search;
    const paramArray = search.substr(1).split('&');
    const params: {[key: string]: string} = {};
    paramArray.forEach(p => {
      const keyValue = p.split('=');
      params[keyValue[0]] = keyValue[1];
    });
    return params;
  }, []);

  return params;
};